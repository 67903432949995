<template>
  <div id="top">
    <div id="contents" :class="{ show: show, first: isFirst }">
      <div id="logo_area">
        <div class="logo">
          <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 52" style="enable-background:new 0 0 250 52;" xml:space="preserve">
            <defs>
              <mask id="mask" fill="none" fill-rule="evenodd" stroke="#fff" stroke-opacity="1" stroke-width="10">
                <path class="st6" d="M30.04,14.39c0,0-10.97-10.72-18.46,0c0,0-4.08,9.22,7.69,11.55c0,0,13.93,3.16,8.44,12.64
                c0,0-1.52,3.43-4.79,4.23c-2.44,0.59-5.19,1.34-8.93-0.15c-2.03-0.81-4.32-2-6.07-6.24"/>
                <path class="st6" d="M41.35,7.6c0,5.5,0,11,0,16.5c0,6.04,0,12.08,0,18.12c2.94,0,5.87,0,8.81,0c3.71,0,7.43,0,11.14,0"/>
                <path class="st6" d="M70.55,7.6v18.17c0,3.82,0,7.65,0,11.47c0,2.77,0,5.54,0,8.3"/>
                <path class="st6" d="M85.91,7.6c0,3.73,0,17.45,0,21.18c0,8.21,0,16.06,0,16.77"/>
                <path class="st6" d="M88.57,10.81c0,0,16.47-1.83,16.47,6.82c0,10.31-9.1,9.1-16.8,9.1"/>
                <path class="st6" d="M142.44,15.63c0,0-3.37-6.01-14.32-6.01c0,0-13.78,3.51-13.78,16.15c0,0-0.67,14.15,14.05,18.05
                c0,0,10.89,0.81,15.21-7.57"/>
                <path class="st6" d="M166.69,9.06c-2.6,5.92-5.2,11.85-7.79,17.77c-2.86,6.52-5.72,13.04-8.58,19.56"/>
                <path class="st6" d="M166.09,7.6c2.63,6.48,5.27,12.96,7.9,19.45c2.51,6.17,5.01,12.33,7.52,18.5"/>
                <path class="st6" d="M156.91,33.25c2.94,0,5.87,0,8.81,0c3.16,0,6.32,0,9.48,0"/>
                <path class="st6" d="M210.01,14.42c0,0-10.97-10.72-18.46,0c0,0-4.08,9.22,7.69,11.55c0,0,13.93,3.16,8.44,12.64
                c0,0-8.73,13.14-19.79-2.16"/>
                <path class="st6" d="M221.59,7.6c0,2.9,0,5.79,0,8.69c0,2.99,0,5.99,0,8.98c0,3.49,0,6.98,0,10.48c0,3.27,0,6.53,0,9.8"/>
                <path class="st6" d="M223.75,10.65c3.16,0,6.32,0,9.48,0c3.49,0,6.98,0,10.48,0"/>
                <path class="st6" d="M223.75,25.77c3.1,0,6.21,0,9.31,0c3.55,0,7.09,0,10.64,0"/>
                <path class="st6" d="M223.75,42.23c3.38,0,6.76,0,10.14,0c3.27,0,6.54,0,9.81,0"/>
              </mask>
            </defs>
            <g class="lines" fill="none" fill-rule="evenodd" stroke="#000" stroke-width="1">
              <path class="p1" d="M27.54,15.2c-0.71-0.99-1.68-1.79-2.91-2.42c-1.22-0.63-2.6-0.94-4.13-0.94c-0.82,0-1.64,0.11-2.47,0.33
              c-0.83,0.22-1.59,0.58-2.27,1.07c-0.68,0.49-1.23,1.11-1.66,1.84c-0.43,0.73-0.64,1.61-0.64,2.63c0,0.99,0.19,1.81,0.59,2.47
              c0.39,0.66,0.92,1.22,1.58,1.68c0.66,0.46,1.44,0.85,2.35,1.17c0.9,0.32,1.86,0.66,2.88,0.99c1.22,0.38,2.46,0.8,3.72,1.28
              c1.26,0.48,2.4,1.11,3.42,1.89c1.02,0.78,1.85,1.76,2.5,2.93c0.65,1.17,0.97,2.66,0.97,4.46c0,1.87-0.35,3.49-1.05,4.87
              c-0.7,1.38-1.62,2.52-2.78,3.42c-1.16,0.9-2.49,1.57-4,2.01c-1.51,0.44-3.07,0.66-4.67,0.66c-2.28,0-4.48-0.44-6.6-1.33
              c-2.13-0.88-3.88-2.19-5.28-3.93l3.93-3.32c0.85,1.26,1.99,2.28,3.42,3.06c1.43,0.78,2.97,1.17,4.64,1.17
              c0.85,0,1.7-0.12,2.55-0.36c0.85-0.24,1.62-0.61,2.32-1.12c0.7-0.51,1.27-1.15,1.71-1.91c0.44-0.76,0.66-1.69,0.66-2.78
              c0-1.09-0.23-2-0.69-2.73s-1.08-1.35-1.86-1.86c-0.78-0.51-1.69-0.94-2.73-1.3c-1.04-0.36-2.13-0.72-3.29-1.1
              c-1.16-0.34-2.29-0.76-3.42-1.25c-1.12-0.49-2.12-1.12-3.01-1.89c-0.88-0.76-1.6-1.71-2.14-2.83c-0.54-1.12-0.82-2.52-0.82-4.18
              c0-1.8,0.36-3.35,1.1-4.64c0.73-1.29,1.68-2.36,2.86-3.21c1.17-0.85,2.5-1.47,3.98-1.86c1.48-0.39,2.97-0.59,4.46-0.59
              c2.11,0,4.08,0.37,5.92,1.12c1.84,0.75,3.31,1.75,4.44,3.01L27.54,15.2z"/>
              <path d="M44.33,40.14h16.12v4.49H39.29V8.52h5.05V40.14z"/>
              <path d="M73.08,44.63h-5.05V8.52h5.05V44.63z"/>
              <path d="M83.53,8.52h11.02c1.94,0,3.71,0.2,5.3,0.59c1.6,0.39,2.96,0.99,4.08,1.81s1.99,1.85,2.6,3.11
              c0.61,1.26,0.92,2.77,0.92,4.54c0,1.8-0.33,3.35-0.99,4.64s-1.58,2.35-2.75,3.16c-1.17,0.82-2.57,1.42-4.18,1.81
              c-1.62,0.39-3.36,0.59-5.23,0.59h-5.71v15.86h-5.05V8.52z M88.58,24.49h5.35c1.22,0,2.35-0.11,3.39-0.33
              c1.04-0.22,1.93-0.56,2.68-1.02c0.75-0.46,1.33-1.06,1.73-1.81c0.41-0.75,0.61-1.67,0.61-2.75c0-1.09-0.21-2-0.64-2.73
              c-0.43-0.73-1-1.33-1.73-1.78c-0.73-0.46-1.61-0.78-2.63-0.97c-1.02-0.19-2.13-0.28-3.31-0.28h-5.46V24.49z"/>
              <path d="M144.52,39.02c-1.43,1.94-3.28,3.51-5.56,4.72c-2.28,1.21-5.02,1.81-8.21,1.81c-2.75,0-5.3-0.47-7.62-1.4
              s-4.33-2.24-6.02-3.93c-1.68-1.68-3-3.69-3.95-6.02c-0.95-2.33-1.43-4.89-1.43-7.68c0-2.82,0.48-5.4,1.45-7.73
              c0.97-2.33,2.31-4.32,4.03-5.97c1.72-1.65,3.75-2.93,6.09-3.85s4.9-1.38,7.65-1.38c1.22,0,2.46,0.12,3.72,0.36
              c1.26,0.24,2.46,0.6,3.62,1.07c1.16,0.48,2.21,1.06,3.16,1.76c0.95,0.7,1.77,1.5,2.45,2.42l-3.98,3.06
              c-0.88-1.22-2.13-2.23-3.75-3.03c-1.62-0.8-3.36-1.2-5.23-1.2c-2.11,0-4,0.38-5.69,1.15c-1.68,0.77-3.12,1.81-4.31,3.14
              c-1.19,1.33-2.11,2.87-2.75,4.62c-0.65,1.75-0.97,3.61-0.97,5.58c0,2.04,0.31,3.94,0.94,5.71c0.63,1.77,1.53,3.31,2.7,4.62
              c1.17,1.31,2.6,2.34,4.28,3.08c1.68,0.75,3.56,1.12,5.64,1.12c2.04,0,3.89-0.41,5.56-1.22s3.04-2.01,4.13-3.57L144.52,39.02z"/>
              <path d="M153.83,44.63h-5.61l15.66-36.11h4.59l15.5,36.11h-5.71l-3.67-8.87h-17.14L153.83,44.63z M159.24,31.32h13.51l-6.73-16.93
              L159.24,31.32z"/>
              <path d="M207.25,15.2c-0.71-0.99-1.68-1.79-2.91-2.42c-1.22-0.63-2.6-0.94-4.13-0.94c-0.82,0-1.64,0.11-2.47,0.33
              c-0.83,0.22-1.59,0.58-2.27,1.07c-0.68,0.49-1.23,1.11-1.66,1.84c-0.43,0.73-0.64,1.61-0.64,2.63c0,0.99,0.2,1.81,0.59,2.47
              c0.39,0.66,0.92,1.22,1.58,1.68s1.44,0.85,2.35,1.17c0.9,0.32,1.86,0.66,2.88,0.99c1.22,0.38,2.46,0.8,3.72,1.28
              c1.26,0.48,2.4,1.11,3.42,1.89c1.02,0.78,1.85,1.76,2.5,2.93c0.65,1.17,0.97,2.66,0.97,4.46c0,1.87-0.35,3.49-1.04,4.87
              c-0.7,1.38-1.62,2.52-2.78,3.42c-1.16,0.9-2.49,1.57-4,2.01c-1.51,0.44-3.07,0.66-4.67,0.66c-2.28,0-4.48-0.44-6.6-1.33
              s-3.88-2.19-5.28-3.93l3.93-3.32c0.85,1.26,1.99,2.28,3.42,3.06c1.43,0.78,2.97,1.17,4.64,1.17c0.85,0,1.7-0.12,2.55-0.36
              c0.85-0.24,1.62-0.61,2.32-1.12c0.7-0.51,1.27-1.15,1.71-1.91c0.44-0.76,0.66-1.69,0.66-2.78c0-1.09-0.23-2-0.69-2.73
              s-1.08-1.35-1.86-1.86c-0.78-0.51-1.69-0.94-2.73-1.3c-1.04-0.36-2.13-0.72-3.29-1.1c-1.16-0.34-2.29-0.76-3.42-1.25
              c-1.12-0.49-2.12-1.12-3.01-1.89c-0.88-0.76-1.6-1.71-2.14-2.83s-0.82-2.52-0.82-4.18c0-1.8,0.37-3.35,1.1-4.64
              c0.73-1.29,1.68-2.36,2.86-3.21c1.17-0.85,2.5-1.47,3.98-1.86c1.48-0.39,2.97-0.59,4.46-0.59c2.11,0,4.08,0.37,5.92,1.12
              c1.84,0.75,3.32,1.75,4.44,3.01L207.25,15.2z"/>
              <path d="M224.04,40.14h18.87v4.49H219V8.52h23.15v4.44h-18.11v10.81h17.03v4.33h-17.03V40.14z"/>
            </g>
            <g fill="black" fill-rule="evenodd" stroke="#000" stroke-width="1" mask="url(#mask)">
              <path class="p1" d="M27.54,15.2c-0.71-0.99-1.68-1.79-2.91-2.42c-1.22-0.63-2.6-0.94-4.13-0.94c-0.82,0-1.64,0.11-2.47,0.33
              c-0.83,0.22-1.59,0.58-2.27,1.07c-0.68,0.49-1.23,1.11-1.66,1.84c-0.43,0.73-0.64,1.61-0.64,2.63c0,0.99,0.19,1.81,0.59,2.47
              c0.39,0.66,0.92,1.22,1.58,1.68c0.66,0.46,1.44,0.85,2.35,1.17c0.9,0.32,1.86,0.66,2.88,0.99c1.22,0.38,2.46,0.8,3.72,1.28
              c1.26,0.48,2.4,1.11,3.42,1.89c1.02,0.78,1.85,1.76,2.5,2.93c0.65,1.17,0.97,2.66,0.97,4.46c0,1.87-0.35,3.49-1.05,4.87
              c-0.7,1.38-1.62,2.52-2.78,3.42c-1.16,0.9-2.49,1.57-4,2.01c-1.51,0.44-3.07,0.66-4.67,0.66c-2.28,0-4.48-0.44-6.6-1.33
              c-2.13-0.88-3.88-2.19-5.28-3.93l3.93-3.32c0.85,1.26,1.99,2.28,3.42,3.06c1.43,0.78,2.97,1.17,4.64,1.17
              c0.85,0,1.7-0.12,2.55-0.36c0.85-0.24,1.62-0.61,2.32-1.12c0.7-0.51,1.27-1.15,1.71-1.91c0.44-0.76,0.66-1.69,0.66-2.78
              c0-1.09-0.23-2-0.69-2.73s-1.08-1.35-1.86-1.86c-0.78-0.51-1.69-0.94-2.73-1.3c-1.04-0.36-2.13-0.72-3.29-1.1
              c-1.16-0.34-2.29-0.76-3.42-1.25c-1.12-0.49-2.12-1.12-3.01-1.89c-0.88-0.76-1.6-1.71-2.14-2.83c-0.54-1.12-0.82-2.52-0.82-4.18
              c0-1.8,0.36-3.35,1.1-4.64c0.73-1.29,1.68-2.36,2.86-3.21c1.17-0.85,2.5-1.47,3.98-1.86c1.48-0.39,2.97-0.59,4.46-0.59
              c2.11,0,4.08,0.37,5.92,1.12c1.84,0.75,3.31,1.75,4.44,3.01L27.54,15.2z"/>
              <path d="M44.33,40.14h16.12v4.49H39.29V8.52h5.05V40.14z"/>
              <path d="M73.08,44.63h-5.05V8.52h5.05V44.63z"/>
              <path d="M83.53,8.52h11.02c1.94,0,3.71,0.2,5.3,0.59c1.6,0.39,2.96,0.99,4.08,1.81s1.99,1.85,2.6,3.11
              c0.61,1.26,0.92,2.77,0.92,4.54c0,1.8-0.33,3.35-0.99,4.64s-1.58,2.35-2.75,3.16c-1.17,0.82-2.57,1.42-4.18,1.81
              c-1.62,0.39-3.36,0.59-5.23,0.59h-5.71v15.86h-5.05V8.52z M88.58,24.49h5.35c1.22,0,2.35-0.11,3.39-0.33
              c1.04-0.22,1.93-0.56,2.68-1.02c0.75-0.46,1.33-1.06,1.73-1.81c0.41-0.75,0.61-1.67,0.61-2.75c0-1.09-0.21-2-0.64-2.73
              c-0.43-0.73-1-1.33-1.73-1.78c-0.73-0.46-1.61-0.78-2.63-0.97c-1.02-0.19-2.13-0.28-3.31-0.28h-5.46V24.49z"/>
              <path d="M144.52,39.02c-1.43,1.94-3.28,3.51-5.56,4.72c-2.28,1.21-5.02,1.81-8.21,1.81c-2.75,0-5.3-0.47-7.62-1.4
              s-4.33-2.24-6.02-3.93c-1.68-1.68-3-3.69-3.95-6.02c-0.95-2.33-1.43-4.89-1.43-7.68c0-2.82,0.48-5.4,1.45-7.73
              c0.97-2.33,2.31-4.32,4.03-5.97c1.72-1.65,3.75-2.93,6.09-3.85s4.9-1.38,7.65-1.38c1.22,0,2.46,0.12,3.72,0.36
              c1.26,0.24,2.46,0.6,3.62,1.07c1.16,0.48,2.21,1.06,3.16,1.76c0.95,0.7,1.77,1.5,2.45,2.42l-3.98,3.06
              c-0.88-1.22-2.13-2.23-3.75-3.03c-1.62-0.8-3.36-1.2-5.23-1.2c-2.11,0-4,0.38-5.69,1.15c-1.68,0.77-3.12,1.81-4.31,3.14
              c-1.19,1.33-2.11,2.87-2.75,4.62c-0.65,1.75-0.97,3.61-0.97,5.58c0,2.04,0.31,3.94,0.94,5.71c0.63,1.77,1.53,3.31,2.7,4.62
              c1.17,1.31,2.6,2.34,4.28,3.08c1.68,0.75,3.56,1.12,5.64,1.12c2.04,0,3.89-0.41,5.56-1.22s3.04-2.01,4.13-3.57L144.52,39.02z"/>
              <path d="M153.83,44.63h-5.61l15.66-36.11h4.59l15.5,36.11h-5.71l-3.67-8.87h-17.14L153.83,44.63z M159.24,31.32h13.51l-6.73-16.93
              L159.24,31.32z"/>
              <path d="M207.25,15.2c-0.71-0.99-1.68-1.79-2.91-2.42c-1.22-0.63-2.6-0.94-4.13-0.94c-0.82,0-1.64,0.11-2.47,0.33
              c-0.83,0.22-1.59,0.58-2.27,1.07c-0.68,0.49-1.23,1.11-1.66,1.84c-0.43,0.73-0.64,1.61-0.64,2.63c0,0.99,0.2,1.81,0.59,2.47
              c0.39,0.66,0.92,1.22,1.58,1.68s1.44,0.85,2.35,1.17c0.9,0.32,1.86,0.66,2.88,0.99c1.22,0.38,2.46,0.8,3.72,1.28
              c1.26,0.48,2.4,1.11,3.42,1.89c1.02,0.78,1.85,1.76,2.5,2.93c0.65,1.17,0.97,2.66,0.97,4.46c0,1.87-0.35,3.49-1.04,4.87
              c-0.7,1.38-1.62,2.52-2.78,3.42c-1.16,0.9-2.49,1.57-4,2.01c-1.51,0.44-3.07,0.66-4.67,0.66c-2.28,0-4.48-0.44-6.6-1.33
              s-3.88-2.19-5.28-3.93l3.93-3.32c0.85,1.26,1.99,2.28,3.42,3.06c1.43,0.78,2.97,1.17,4.64,1.17c0.85,0,1.7-0.12,2.55-0.36
              c0.85-0.24,1.62-0.61,2.32-1.12c0.7-0.51,1.27-1.15,1.71-1.91c0.44-0.76,0.66-1.69,0.66-2.78c0-1.09-0.23-2-0.69-2.73
              s-1.08-1.35-1.86-1.86c-0.78-0.51-1.69-0.94-2.73-1.3c-1.04-0.36-2.13-0.72-3.29-1.1c-1.16-0.34-2.29-0.76-3.42-1.25
              c-1.12-0.49-2.12-1.12-3.01-1.89c-0.88-0.76-1.6-1.71-2.14-2.83s-0.82-2.52-0.82-4.18c0-1.8,0.37-3.35,1.1-4.64
              c0.73-1.29,1.68-2.36,2.86-3.21c1.17-0.85,2.5-1.47,3.98-1.86c1.48-0.39,2.97-0.59,4.46-0.59c2.11,0,4.08,0.37,5.92,1.12
              c1.84,0.75,3.32,1.75,4.44,3.01L207.25,15.2z"/>
              <path d="M224.04,40.14h18.87v4.49H219V8.52h23.15v4.44h-18.11v10.81h17.03v4.33h-17.03V40.14z"/>
            </g>
          </svg>
        </div>

        <div class="sub">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 18" style="enable-background:new 0 0 250 18;" xml:space="preserve">
            <g fill="none" fill-rule="evenodd" stroke="#000">
              <path d="M13.223,11.669c-0.548,0.213-1.106,0.318-1.674,0.318c-0.64,0-1.224-0.111-1.752-0.336
              c-0.528-0.224-0.984-0.533-1.368-0.93C8.045,10.326,7.747,9.852,7.535,9.3C7.323,8.748,7.217,8.148,7.217,7.5
              s0.106-1.248,0.318-1.8c0.212-0.552,0.51-1.026,0.894-1.422c0.384-0.396,0.842-0.706,1.374-0.93
              c0.532-0.224,1.114-0.336,1.746-0.336c0.528,0,1.042,0.09,1.542,0.27c0.5,0.18,0.926,0.454,1.278,0.822l-0.168,0.192
              c-0.168-0.176-0.358-0.33-0.57-0.462s-0.436-0.242-0.672-0.33c-0.236-0.088-0.474-0.152-0.714-0.192
              c-0.24-0.04-0.472-0.06-0.696-0.06c-0.608,0-1.162,0.11-1.662,0.33c-0.5,0.22-0.928,0.522-1.284,0.906
              c-0.356,0.384-0.632,0.834-0.828,1.35C7.579,6.354,7.481,6.908,7.481,7.5c0,0.592,0.096,1.146,0.288,1.662
              c0.192,0.517,0.466,0.967,0.822,1.351s0.784,0.687,1.284,0.905c0.5,0.221,1.058,0.33,1.674,0.33c0.528,0,1.05-0.102,1.566-0.306
              c0.516-0.204,0.942-0.51,1.278-0.918l0.192,0.181C14.225,11.136,13.771,11.458,13.223,11.669z"/>
              <path d="M27.737,11.748l-2.568-4.284h-1.992v4.284h-0.264V3.252h2.352c0.312,0,0.628,0.028,0.948,0.084
              c0.32,0.056,0.606,0.162,0.858,0.318c0.252,0.156,0.458,0.371,0.618,0.642c0.16,0.272,0.24,0.624,0.24,1.056
              c0,0.4-0.076,0.732-0.228,0.996c-0.152,0.264-0.348,0.478-0.588,0.642c-0.24,0.164-0.506,0.282-0.798,0.354
              c-0.292,0.072-0.578,0.108-0.858,0.108l2.58,4.295H27.737z M27.665,5.352c0-0.368-0.072-0.674-0.216-0.918
              s-0.334-0.436-0.57-0.576c-0.236-0.14-0.508-0.238-0.816-0.294c-0.308-0.056-0.622-0.084-0.942-0.084h-1.944v3.756h2.076
              c0.784,0,1.382-0.164,1.794-0.492S27.665,5.952,27.665,5.352z"/>
              <path d="M36.641,11.748V3.252h4.956v0.24h-4.692v3.732h4.416v0.24h-4.416v4.043h4.884v0.24H36.641z"/>
              <path d="M56.537,11.748l-1.008-2.436h-4.932l-1.044,2.436h-0.288l3.684-8.496h0.336l3.54,8.496H56.537z M53.105,3.42l-2.412,5.664
              h4.752L53.105,3.42z"/>
              <path d="M67.013,3.492v8.255h-0.264V3.492h-2.892v-0.24h6.048v0.24H67.013z"/>
              <path d="M77.993,11.748V3.252h0.264v8.496H77.993z"/>
              <path d="M89.945,11.748h-0.408l-3.192-8.496h0.288l3.096,8.255h0.024l3.144-8.255h0.288L89.945,11.748z"/>
              <path d="M101.273,11.748V3.252h4.956v0.24h-4.692v3.732h4.416v0.24h-4.416v4.043h4.884v0.24H101.273z"/>
              <path d="M131.645,7.5c0,0.736-0.119,1.371-0.359,1.902c-0.24,0.532-0.57,0.973-0.994,1.32s-0.92,0.605-1.492,0.773
              c-0.57,0.168-1.184,0.252-1.838,0.252h-2.408V3.252h2.408c0.654,0,1.268,0.082,1.838,0.246c0.572,0.164,1.068,0.42,1.492,0.768
              s0.754,0.79,0.994,1.326C131.525,6.128,131.645,6.764,131.645,7.5z M124.817,3.492v8.015h2.08c0.625,0,1.213-0.077,1.762-0.233
              c0.549-0.155,1.023-0.396,1.424-0.72s0.717-0.738,0.949-1.242s0.35-1.107,0.35-1.812c0-0.704-0.117-1.31-0.35-1.818
              s-0.549-0.924-0.949-1.248c-0.4-0.324-0.875-0.562-1.424-0.714s-1.137-0.228-1.762-0.228H124.817z"/>
              <path d="M140.393,11.748V3.252h4.955v0.24h-4.691v3.732h4.416v0.24h-4.416v4.043h4.884v0.24H140.393z"/>
              <path d="M156.617,11.748h-0.408l-3.192-8.496h0.288l3.096,8.255h0.024l3.144-8.255h0.288L156.617,11.748z"/>
              <path d="M167.945,11.748V3.252h4.955v0.24h-4.691v3.732h4.416v0.24h-4.416v4.043h4.884v0.24H167.945z"/>
              <path d="M181.625,11.748V3.252h0.264v8.255h4.224v0.24H181.625z"/>
              <path d="M202.528,7.5c0,0.656-0.109,1.258-0.33,1.806c-0.22,0.548-0.523,1.02-0.911,1.416c-0.389,0.396-0.851,0.706-1.387,0.93
              c-0.536,0.225-1.115,0.336-1.739,0.336s-1.204-0.111-1.74-0.336c-0.536-0.224-0.998-0.533-1.386-0.93
              c-0.389-0.396-0.692-0.868-0.912-1.416c-0.221-0.548-0.33-1.15-0.33-1.806s0.109-1.258,0.33-1.806
              c0.22-0.548,0.523-1.02,0.912-1.416c0.388-0.396,0.848-0.706,1.38-0.93c0.531-0.224,1.113-0.336,1.746-0.336
              c0.632,0,1.214,0.112,1.745,0.336c0.532,0.224,0.992,0.534,1.381,0.93c0.388,0.396,0.691,0.868,0.911,1.416
              C202.419,6.242,202.528,6.844,202.528,7.5z M202.265,7.5c0-0.592-0.1-1.146-0.3-1.662c-0.2-0.516-0.482-0.966-0.846-1.35
              c-0.364-0.384-0.799-0.686-1.303-0.906c-0.504-0.22-1.056-0.33-1.655-0.33c-0.601,0-1.152,0.11-1.656,0.33
              c-0.504,0.22-0.938,0.522-1.302,0.906c-0.364,0.384-0.646,0.834-0.847,1.35c-0.2,0.516-0.3,1.07-0.3,1.662
              c0,0.592,0.098,1.146,0.294,1.662c0.196,0.517,0.475,0.967,0.834,1.351c0.36,0.384,0.792,0.687,1.296,0.905
              c0.504,0.221,1.064,0.33,1.681,0.33c0.615,0,1.176-0.109,1.68-0.33c0.504-0.219,0.936-0.521,1.296-0.905s0.638-0.834,0.834-1.351
              C202.167,8.646,202.265,8.092,202.265,7.5z"/>
              <path d="M216.196,5.364c0,0.36-0.066,0.678-0.198,0.954c-0.132,0.276-0.31,0.506-0.534,0.69c-0.224,0.185-0.485,0.324-0.786,0.42
              c-0.3,0.096-0.617,0.144-0.953,0.144h-2.196v4.175h-0.264V3.252h2.304c0.304,0,0.612,0.034,0.924,0.102s0.594,0.182,0.846,0.342
              c0.252,0.16,0.458,0.376,0.618,0.648S216.196,4.956,216.196,5.364z M215.933,5.364c0-0.36-0.068-0.66-0.204-0.9
              c-0.137-0.24-0.312-0.432-0.528-0.576s-0.458-0.248-0.726-0.312c-0.269-0.064-0.534-0.096-0.799-0.096h-2.147v3.864h2.208
              c0.264,0,0.528-0.036,0.792-0.108c0.264-0.072,0.5-0.188,0.708-0.348s0.376-0.366,0.504-0.618S215.933,5.716,215.933,5.364z"/>
              <path d="M224.729,11.748V3.252h4.955v0.24h-4.691v3.732h4.416v0.24h-4.416v4.043h4.884v0.24H224.729z"/>
              <path d="M243.231,11.748l-2.567-4.284h-1.992v4.284h-0.264V3.252h2.352c0.312,0,0.628,0.028,0.948,0.084
              c0.319,0.056,0.605,0.162,0.857,0.318c0.252,0.156,0.458,0.371,0.618,0.642c0.16,0.272,0.24,0.624,0.24,1.056
              c0,0.4-0.076,0.732-0.229,0.996c-0.151,0.264-0.348,0.478-0.588,0.642c-0.239,0.164-0.506,0.282-0.798,0.354
              c-0.292,0.072-0.578,0.108-0.857,0.108l2.58,4.295H243.231z M243.16,5.352c0-0.368-0.072-0.674-0.217-0.918
              c-0.144-0.244-0.334-0.436-0.569-0.576c-0.236-0.14-0.509-0.238-0.816-0.294c-0.308-0.056-0.622-0.084-0.941-0.084h-1.944v3.756
              h2.076c0.783,0,1.382-0.164,1.794-0.492S243.16,5.952,243.16,5.352z"/>
            </g>
          </svg>
        </div>
      </div>

      <div id="description">
        <p class="text pc"><span class="inner">Webサービス開発を中心に活動しているクリエティブデベロッパーです。</span></p>
        <p class="text sp"><span class="inner">Webサービス開発を中心に活動している<br>クリエティブデベロッパーです。</span></p>
        <div class="moreWrap"><p class="more" :class="{ active: state.isAboutActive }"><span><a href="#" @click="toAbout()">MORE</a></span></p></div>
      </div>

      <div class="btns">
        <ul>
          <li class="contact"><div><a href="mailto:info@slip-case.com"><p class="name"><span>CONTACT</span></p></a></div></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
import $ from 'jquery';
import { reactive, onMounted, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'Home',
  data () {
    return {
      show: false,
      isFirst: false,
      aboutMoved: false
    };
  },
  setup () {
    const router = useRouter();
    // const route = useRoute();

    const store = useStore();

    const state = reactive({
      isAboutActive: 0
    });

    const resize = () => {
      elementSetup();
    };

    const toAbout = () => {

      $('#aboutTransition .pageTransitionLine').css({
        'top' : $('#top #description .more').offset().top + $('#top #description .more').height() / 2 + 'px'
      });

      state.isAboutActive = true;
      store.dispatch('setPage', 'about');
      var animationMonitor = false;
      animationMonitor = setInterval( function() {
        if ($('#aboutTransition .whoweare span').width() > $(window).width() * .7) {
          clearInterval(animationMonitor);
          router.push('/about');
        }
      }, 10);
    };

    const elementSetup = () => {
    };

    $(window).on('resize', function () {
      resize();
    });

    onMounted(() => {
      elementSetup();
    });

    onBeforeMount(() => {
      store.dispatch('setPage', 'home');
    });

    return {
      state,
      toAbout
    };

  },
  created () {
    this.$store.dispatch('setPage', 'home');
    var _this = this;
    if (!this.$store.getters.getIsSiteLoaded) {
      this.$store.dispatch('setIsSiteLoaded', true);
      this.isFirst = true;
      var showDelay = false;
      var ua = window.navigator.userAgent.toLowerCase();
      var delayTime = (ua.indexOf('edge') !== -1) ? 1000 : 100;
      showDelay = setInterval(function () {
        logoAnimation();
        clearInterval(showDelay);
        _this.show = true;
      }, delayTime);
    } else {
      _this.show = true;
      this.isFirst = false;
    }
  }
};

function logoAnimation () {
  var logoTimeline = anime.timeline();
  // var logoLine = document.querySelector('#logo_area .lines');

  logoTimeline.add({
    targets: '#logo_area .lines path',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutSine',
    duration: 300,
    delay: function (el, i) { return i * 200; },
    direction: 'alternate',
    loop: false,
  }).add({
    targets: '#logo_area #mask path',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutSine',
    duration: 300,
    delay: function (el, i) { return i * 135; },
    direction: 'alternate',
    loop: false,
    offset: '-=1400'
  }).add({
    targets: '#logo_area .sub path',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutSine',
    duration: 300,
    delay: function (el, i) { return i * 135; },
    direction: 'alternate',
    loop: false,
    offset: '-=2500'
  });
}
</script>

<style lang="scss" scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
#top{
  position: absolute;
  width: 100%;
  height: 100%;
  #contents {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    #logo {
      width: 200px;
      margin: 0 auto;
    }
    #name {
      width: 200px;
      margin:0 auto;
    }
    #description {
      position: relative;
      font-size: 15px;
      text-align: center;
      width: 300px;
      height: 55px;
      margin: 0 auto;
      @media screen and (min-width:640px) {
        width: 500px;
        height: 65px;
      }
      &:before {
        display: block;
        content: '';
        position: absolute;
        top: calc(50% - 5px);
        left: 0;
        width: 0;
        height: 1px;
        background: #000;
        @media screen and (min-width:640px) {
          top: 15px;
        }
      }
      p {
        position: relative;
      }
      .text {
        position: absolute;
        overflow: hidden;
        width: 0;
        height: 50px;
        line-height: 22px;
        left: 50%;
        margin-left: -150px;
        transition: all .8s ease;
        transition-delay: 3.85s;
        text-align: center;
        @media screen and (min-width:640px) {
          margin-left: -250px;
          height: 30px;
          line-height: 30px;
          transition-delay: 3.5s;
        }
      }
      .moreWrap {
        position: relative;
        width: 110px;
        margin: 0 auto;
        padding: 50px 0 0;
        height: 35px;
        @media screen and (min-width:640px) {
          padding: 30px 0 0;
        }
        &:after {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          left: 0;
          bottom: 16.5px;
          background: #000;
        }
        .more {
          display: block;
          position: absolute;
          width: 0;
          height: 35px;
          line-height: 35px;
          left: 50%;
          margin: 0 0 0 -55px;
          transition: all 1s ease;
          transition-delay: 3.85s;
          overflow: hidden;
          text-align: center;
          @media screen and (min-width:640px) {
            margin: 0 0 0 -55px;
            transition-delay: 3.4s;
          }
          &:before {
            position: absolute;
            content: '';
            display: block;
            width: 20px;
            height: 1px;
            background: #000;
            top: 50%;
            left: 0;
            animation: line2 1s ease-in-out infinite;
          }
          span {
            position: absolute;
            display: block;
            width: 110px;
            left: 0;
            margin: 0;
          }
          a {
            color: #000;
            text-decoration: none;
            font-weight: bold;
          }
        }
      }
      .inner {
        position: absolute;
        width: 300px;
        left: 0;
        font-size: .8rem;
        @media screen and (min-width:640px) {
          width: 500px;
          font-size: .9rem;
        }
      }
    }
    .btns {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 70px auto 0;
    }
  }
}
#top #contents .btns ul li {
  position: relative;
  display: block;
  width: 130px;
  height: 40px;
  @media screen and (min-width:640px) {
    margin-top: 50px;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 19px;
    width: 0;
    height: 1px;
    background: #000;
  }
  div {
    position: absolute;
    width: 0;
    height: 100%;
    left: 0;
    overflow: hidden;
    transition: all 1s ease;
    transition-delay: 3.85s;
    @media screen and (min-width:640px) {
      transition-delay: 3.4s;
    }
    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #000;
      animation: line1 1s ease-in-out infinite;
    }
    a{
      position: absolute;
      width: 130px;
      text-decoration: none;
      padding: 10px 0;
      span {
        font-weight: bold;
      }
      p {
        text-align: center;
      }
    }
  }
}
#top #contents.show {
  #description {
    .text {
      width: 300px;
      @media screen and (min-width:640px) {
        width: 500px;
      }
    }
    .more {
      width: 110px;
      &.active {
        transition: all .5s ease;
        transition-delay: 0;
        width: 0;
      }
    }
  }
  .btns ul li {
    div {
      width: 100%;
    }
  }
}
#top #contents.first {
  #description {
    &:before {
      animation: line3 1s ease-in-out;
      animation-delay: 3.2s;
    }
    .moreWrap {
      &:after {
        animation: line3 1s ease-in-out;
        animation-delay: 3.2s;
      }
    }
  }
  .btns ul li {
    &:before {
      animation: line4 1s ease-in-out;
      animation-delay: 3.2s;
    }
  }
}
#top #contents .btns ul li:last-child{
  position: relative;
}
#top #contents .btns ul li a p.name{
  color: #000;
  letter-spacing: 2px;
  position: relative;
  font-weight: bold;
}
@media screen and (min-width:640px) {
  #top #contents{
    width: 560px;
    height: 294px;
    top: 50%;
    left: 50%;
    z-index: 1;
    #logo {
      width: 250px;
      height: 44px;
    }
    #name{
      width: 250px;
      height: 34px;
    }
    .btns{
      margin: 65px 0 auto;
    }
  }
  #top #contents .btns ul li{
    float: left;
    margin: 0 40px 0 0;
  }
  #top #contents .btns ul li:last-child{
    margin-right: 0;
  }

}
@keyframes line1 {
  0% {
    width: 0;
    left: 0;
  }
  40% {
    width: 100%;
    left: 0;
  }
  60% {
    width: 100%;
    left: 0;
  }
  90% {
    width: 0;
    left: 100%;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes line2 {
  0% {
    width: 0;
    left: 0;
  }
  40% {
    width: 20px;
    left: 0;
  }
  60% {
    width: 20px;
    left: 0;
  }
  90% {
    width: 0;
    left: 20px;
  }
  100% {
    width: 0;
    left: 20px;
  }
}
@keyframes line3 {
  0% {
    width: 0;
    left: 0;
  }
  40% {
    width: 100%;
    left: 0;
  }
  60% {
    width: 100%;
    left: 0;
  }
  90% {
    width: 0;
    left: 100%;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes line4 {
  0% {
    width: 0;
    left: 0;
    margin-left: 0;
  }
  40% {
    width: 100%;
    left: 0;
    margin-left: 0;
  }
  60% {
    width: 100%;
    left: 0;
    margin-left: 0;
  }
  90% {
    width: 0;
    left: 100%;
    margin-left: 0;
  }
  100% {
    width: 0;
    left: 100%;
    margin-left: 0;
  }
}
</style>
