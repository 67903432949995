import $ from 'jquery';

let drawActive;

const p5Setup2 = function (p) {
    /** 初期化処理 */

    let r = 3;
    let initX = [];
    let initY = [];
    let initVX = [];
    let initVY = [];
    const particles = 20;
    const lineWidth = 22;
    const divide = 8;
    let singleWidth = 0;

    p.setup = function () {
      const canvas = p.createCanvas($('#canvas2').width(), $('#canvas2').height());
      canvas.parent('canvas2');
      singleWidth = p.width / divide;
      for (let d = 0; d < divide; d++) {
        initX[d] = new Array();
        initY[d] = [];
        initVX[d] = [];
        initVY[d] = [];
        for (let n = 0; n < particles; n++) {
          if (d == divide - 1) {
            initX[d][n] = p.random() * (p.width - singleWidth * (d + 1)) + singleWidth * (d + 1);
          } else {
            initX[d][n] = p.random() * (singleWidth * (d + 2) - singleWidth * (d + 1)) + singleWidth * (d + 1);
          }
          initY[d][n] = p.random(p.height);
          initVX[d][n] = .75;
          initVY[d][n] = .75;
        }
      }
      p.frameRate(20);
      //p.createCanvas(p.windowWidth, p.windowHeight);

      drawActive = true;

    };
    /** フレームごとの描画処理 */
    p.draw = function () {

      if (!drawActive) p.noLoop();

      //p.background(40);
      p.fill(0, 20);
      p.rect(0, 0, p.width, p.height);

      p.noFill();
      let c = p.color(255, 255, 255);
      p.fill(c);
      p.noStroke();

      for (let divideIndex = 0; divideIndex < divide; divideIndex++) {
        for (let i = 0; i < particles; i++) {
          p.ellipse(initX[divideIndex][i], initY[divideIndex][i], r);
          initX[divideIndex][i] += initVX[divideIndex][i];
          initY[divideIndex][i] += initVY[divideIndex][i];

          let widthEnd;

          if (divideIndex == divide - 1) {
            widthEnd = p.width;
          } else {
            widthEnd = singleWidth * (divideIndex + 1);
          }

          if(initX[divideIndex][i] < (singleWidth * divideIndex) || initX[divideIndex][i] > widthEnd){
            initX[divideIndex][i] = Math.floor( Math.random() * singleWidth) + singleWidth * divideIndex;
            initVX[divideIndex][i] = -initVX[divideIndex][i];
          }
          if(initY[divideIndex][i] < 0 || initY[divideIndex][i] > p.height){
            initY[divideIndex][i] = p.random(p.height);
            initVY[divideIndex][i] = -initVY[divideIndex][i];
          }

          p.strokeWeight(.5);
          p.stroke(120);
          for (let index = 0; index < particles; index++) {
            let diffX = Math.abs(initX[divideIndex][i] - initX[divideIndex][index]);
            let diffY = Math.abs(initY[divideIndex][i] - initY[divideIndex][index]);
            if (diffX < lineWidth && diffY < lineWidth) {
              p.line(initX[divideIndex][i], initY[divideIndex][i], initX[divideIndex][index], initY[divideIndex][index]);
            }
          }
        }
      }

    };
};

const drawDisactive2 = () => {
  drawActive = false;
};

export { p5Setup2, drawDisactive2 };
