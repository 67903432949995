import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueParticles from 'vue-particles';

require('@/assets/css/reset.css');
require('@/assets/css/style.css');

createApp(App).use(store).use(router).use(VueParticles).mount('#app');
