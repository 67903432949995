import { createStore } from 'vuex';

export default createStore({
  state: {
    page: '',
    pageAboutTransitionEnd: false,
    isSiteLoaded: false
  },
  getters: {
    getPage: state => {
      return state.page;
    },
    getPageAboutTransitionEnd: state => {
      return state.pageAboutTransitionEnd;
    },
    getIsSiteLoaded: state => {
      return state.isSiteLoaded;
    }
  },
  mutations: {
    setPage (state, name) {
      state.page = name;
    },
    setPageAboutTransitionEnd (state, bool) {
      state.pageAboutTransitionEnd = bool;
    },
    setIsSiteLoaded (state, bool) {
      state.isSiteLoaded = bool;
    }
  },
  actions: {
    setPage ({ commit }, data) {
      commit('setPage', data);
    },
    setPageAboutTransitionEnd ({ commit }, data) {
      commit('setPageAboutTransitionEnd', data);
    },
    setIsSiteLoaded ({ commit }, data) {
      commit('setIsSiteLoaded', data);
    }
  },
  modules: {
  }
});
